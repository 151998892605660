import {
  bodyFont,
  Button,
  Collapsible,
  colors,
  Column,
  Container,
  respondTo,
  Row,
  titleFont,
} from '@123-front/ui-kit';
import '@brainhubeu/react-carousel/lib/style.css';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Banner from '../../../components/banner';
import Card from '../../../components/card';
import FlexCenter from '../../../components/flex-center';
import FooterLanding from '../../../components/footer-landing';
import GroupingLine from '../../../components/GroupingLine';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import OpinionsCarousel from '../../../components/opinions-carousel';
import SEO from '../../../components/seo';

const ButtonCotizar = styled.div({
  [respondTo('xs', 'md')]: {
    marginTop: -107,
  },
});

const SectionTitle = styled.h2({
  ...titleFont('md', 'serif'),
  color: colors.gray.darkest,
  textAlign: 'center',
});

const HeroSection = styled.section({
  [respondTo('xs', 'md')]: { height: 280 },
  [respondTo('md')]: { height: 400 },
});

const CardSection = styled.section({
  [respondTo('xs', 'md')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 72,
    paddingBottom: 100,
  },
  [respondTo('md')]: { paddingTop: 140, paddingBottom: 140 },
});

const OpinionSection = styled.section({
  background: colors.gray.ultralight,
  [respondTo('xs', 'md')]: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  [respondTo('md')]: {
    paddingTop: 60,
    paddingBottom: 70,
  },
});

const ExpertSection = styled.section({
  [respondTo('xs', 'md')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 100,
    paddingBottom: 120,
  },
  [respondTo('md')]: { paddingTop: 140, paddingBottom: 160 },
});

const AdvisorImg = styled.img({
  width: 270,
  height: 217,
  [respondTo('xs', 'md')]: {
    marginBottom: 16,
  },
  [respondTo('md')]: {
    marginTop: -76,
  },
});

const OrganicLandingUy: React.FC = () => {
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "cotizar-seguros-de-autos_sm" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "cotizar-seguros-de-autos_lg" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);

  return (
    <Layout gtmId="GTM-M9T3L4F">
      <SEO
        title="Seguros de Autos. Cotizaciones de Seguro de Auto"
        siteTitle="123Seguro Uruguay"
        description="¡Encontrar Seguros de Autos ahora es más fácil! En 123Seguro vas a poder acceder a una gran variedad de opciones para tu seguro automotor y elegir el que mejor se adapte a vos.!"
      />
      <HeroSection id="hero">
        <Hero
          desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
          mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
          title="SEGUROS DE AUTOS"
          subtitle="Contratá el seguro de\nauto a tu medida"
        />
        <FlexCenter>
          <ButtonCotizar>
            <Button size="lg"> Cotizar seguros de autos</Button>
          </ButtonCotizar>
        </FlexCenter>
      </HeroSection>
      <CardSection id="cards">
        <FlexCenter>
          <SectionTitle>Un seguro para auto que se adapta a tus necesidades</SectionTitle>
        </FlexCenter>
        <Container desktop="12" mobile="4">
          <Row>
            <Card
              image="https://branding.s3.us-west-2.amazonaws.com/images/img_tech.svg"
              title="Todo a un click de distancia"
              body="Podés cotizar el seguro de un auto online y comparar diferentes precios y coberturas desde donde estés."
            ></Card>
            <Card
              image="https://branding.s3.us-west-2.amazonaws.com/images/img_callcenter.svg"
              title="Estamos siempre"
              body="Te asesoramos en el proceso de contratación y acompañamos durante toda la vida de tu póliza."
            ></Card>
            <Card
              image="https://branding.s3.us-west-2.amazonaws.com/images/img_experience.svg"
              title="Planes imperdibles"
              body="La cobertura que necesitás al precio mas conveniente. Elegí el seguro para tu auto, nosotros lo gestionamos."
            ></Card>
          </Row>
        </Container>
      </CardSection>
      <GroupingLine />
      <OpinionSection id="opinions">
        <FlexCenter>
          <SectionTitle>Opiniones reales, de clientes reales</SectionTitle>
        </FlexCenter>
        <Container>
          <OpinionsCarousel />
        </Container>
      </OpinionSection>
      <GroupingLine />
      <ExpertSection id="experts">
        <FlexCenter>
          <SectionTitle>Somos expertos en Seguros</SectionTitle>
        </FlexCenter>
        <FlexCenter>
          <Container>
            <Row>
              <Column desktop="3">
                <FlexCenter>
                  <AdvisorImg src="https://branding.s3.us-west-2.amazonaws.com/photos/photo_landing_advisors.png"></AdvisorImg>
                </FlexCenter>
              </Column>
              <Column desktop="6">
                <p style={{ textAlign: 'center' }}>
                  Somos un <strong style={{ fontWeight: 'normal' }}>Bróker de Seguros</strong>{' '}
                  digital, pioneros en latinoamérica. Nacimos en 2010 con el objetivo de hacer
                  transparente la contratación de seguros y actualmente asesoramos a más de un
                  millón de personas en distintas partes de LaTam.
                </p>
                <Container>
                  <Row>
                    <Column desktop="4" mobile="4">
                      <div
                        style={{
                          borderLeft: `4px solid ${colors.gray.lighten}`,
                          paddingLeft: 12,
                          paddingTop: 5,
                          paddingBottom: 5,
                          height: '100%',
                        }}
                      >
                        <div style={titleFont('sm')}>+ 35.000</div>
                        <div style={bodyFont('md')}>Consultas resueltas por año</div>
                      </div>
                    </Column>
                    <Column desktop="4" mobile="4">
                      <div
                        style={{
                          borderLeft: `4px solid ${colors.gray.lighten}`,
                          paddingLeft: 12,
                          paddingTop: 5,
                          paddingBottom: 5,
                          height: '100%',
                        }}
                      >
                        <div style={titleFont('sm')}>+ 5.500</div>
                        <div style={bodyFont('md')}>Siniestros resueltos por año</div>
                      </div>
                    </Column>
                    <Column desktop="4" mobile="4">
                      <div
                        style={{
                          borderLeft: `4px solid ${colors.gray.lighten}`,
                          paddingLeft: 12,
                          paddingTop: 5,
                          paddingBottom: 5,
                          height: '100%',
                        }}
                      >
                        <div style={titleFont('sm')}>95 %</div>
                        <div style={bodyFont('md')}>Clientes satisfechos</div>
                      </div>
                    </Column>
                  </Row>
                </Container>
              </Column>
            </Row>
            <Row>
              <FlexCenter style={{ paddingTop: 72 }}>
                <Column desktop="6">
                  <Collapsible title="¿Qué es 123Seguro?" type="list">
                    <p>
                      Somos un <em style={{ fontStyle: 'normal' }}>bróker de seguros</em> online
                      líder. En 123Seguro brindamos una solución integral, te ofrecemos una
                      multi-cotización, además de asesoramiento en la contratación, siniestros y de
                      cualquier trámite relacionado con tu póliza de seguro.
                    </p>
                  </Collapsible>
                  <Collapsible
                    title="¿Cuál es la importancia de tener un Seguro de Auto?"
                    type="list"
                  >
                    <p>
                      En primer lugar existe la obligación de contratar un{' '}
                      <strong style={{ fontWeight: 'normal' }}>
                        Seguro de Responsabilidad Civil
                      </strong>{' '}
                      estipulado por la legislación. El conductor, que circule sin seguro será
                      multado y en algunos casos se le puede retener el auto.
                    </p>

                    <p>
                      Pero lo más importante es contar con un{' '}
                      <strong style={{ fontWeight: 'normal' }}>Seguro de Auto</strong> para proteger
                      tu integridad, la de tus acompañantes y la de terceros. Además por supuesto de
                      prever gastos en un posible siniestro que lamentablemente a todos nos puede
                      suceder.
                    </p>
                  </Collapsible>
                  <Collapsible title="¿Qué es un siniestro?" type="list">
                    <p>
                      Un siniestro es un elemento clave en un contrato de seguro. Se dice que se ha
                      producido un siniestro cuando ocurre alguno de los hechos asegurados que se
                      contemplan en la{' '}
                      <strong style={{ fontWeight: 'normal' }}>póliza de seguros</strong>. Por
                      ejemplo en el{' '}
                      <strong style={{ fontWeight: 'normal' }}>seguro para auto</strong>, se produce
                      un siniestro cuando el asegurado tiene un accidente.
                    </p>
                  </Collapsible>
                  <Collapsible
                    title="¿Cuál es el beneficio de tener mi seguro para auto con 123Seguro?"
                    type="list"
                  >
                    <p>
                      Uno de los grandes beneficios que aporta 123 a tu{' '}
                      <em style={{ fontStyle: 'normal' }}>seguro de auto</em>, es la gestión
                      personalizada en nuestros diferentes canales de atención. Contamos con un 99%
                      de efectividad en respuestas. En 123 estamos siempre.
                    </p>
                  </Collapsible>
                  <GroupingLine />
                </Column>
              </FlexCenter>
            </Row>
          </Container>
        </FlexCenter>
      </ExpertSection>
      <Banner
        textTitle="¡Contratá en pocos minutos!"
        textSubtitle="Empezá a ahorrar tiempo y dinero con la asesoría de 123Seguro."
      />
      <FooterLanding email="holauruguay@123seguro.com" />
    </Layout>
  );
};

export default OrganicLandingUy;
